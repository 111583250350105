/*jshint esversion: 11 */

import PreparePayload from "./PreparePayload";
import { apiUrl, localforage, taskId, TOKEN_UUID } from "../config";

/**
 * Submit Payload to FotoHub
 *
 * @param data
 * @returns {Promise<Response>}
 */
export default async function submitData ( data ) {
    const url = `${ apiUrl }/upload`;
    const payload = PreparePayload( data );
    const headers = {
        "Content-Type": "application/json"
    };

    const credentials = await localforage.getItem( taskId + TOKEN_UUID );
    payload.sys = { ...credentials };

    try {

        return await fetch( url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify( payload ),
        } );

    } catch (err) {
        return err;
    }
}

